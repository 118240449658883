import { useEffect } from 'react'
import { useOffers } from '../../hooks/useOffers'
import { CheckoutPage } from '../CheckoutPage'

export const LoveCheckout: React.FC = () => {
  const { offers, setIsPickOfferFirstFlow, setSelectedOffer } =
    useOffers()

  useEffect(() => {
    if (offers?.length) {
      setIsPickOfferFirstFlow(true)
      setSelectedOffer(offers[1])
    }
  }, [offers, setIsPickOfferFirstFlow, setSelectedOffer])

  return (
    <CheckoutPage
      couponCode=""
      couponComponent={
        <div className="coupon-display" >
          <div><span className='textbefore'>Coupon Code Applied:</span> <span className='coupontext'>LOVE</span></div>
        </div>
      }
      //bannerImage='/checkoutLeft.png'
      backgroundColor="var(--Bone)"
      titleOverride="Annual + Enrichment Box"
    />
  )
}
